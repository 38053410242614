import { NgModule, ErrorHandler } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';

import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';

import { MAT_DATE_LOCALE } from '@angular/material/core';

import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { AppInterceptor } from './shared/interceptors/app.interceptor';
import { rootRouterConfig } from './app.routing';
import { AppConfig } from './app.config';
import { JwtAuthService } from './shared/services/api/auth/jwt-auth.service';
import { EventService } from './shared/services/api/events/event.service';
import { OrganiserService } from './shared/services/api/organiser.service';
import { MembershipService } from './shared/services/api/membership.service';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from 'environments/environment';
import { DeviceService } from './shared/services/api/device.service';
import { MessagingService } from './shared/services/api/messaging.service';
import { MatSnackbarComponent } from './shared/components/mat-snackbar/mat-snackbar.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname,
  },
  palette: {
    popup: {
      background: '#222a49',
    },
    button: {
      background: '#f1d600',
    },
  },
  theme: 'edgeless',
  type: 'info',
  layout: 'my-custom-layout',
  layouts: {
    'my-custom-layout': '{{messagelink}}{{compliance}}',
  },
  elements: {
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}} 
      <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank" rel="noopener">{{privacyPolicyLink}}</a>.
    </span>
    `,
  },
  content: {
    message: 'By using our site, you acknowledge that you have read and understand our ',
    privacyPolicyLink: 'Privacy Policy',
    privacyPolicyHref: '/#/privacy-policy',
  },
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    RouterModule.forRoot(rootRouterConfig, {
      useHash: true, // Use hash-based routing
      scrollPositionRestoration: 'enabled', // Restore scroll position
      anchorScrolling: 'enabled', // Enable anchor scrolling
      onSameUrlNavigation: 'reload', // Reload on same URL navigation
      scrollOffset: [0, 50], // Offset for scroll position
    })
  ],
  declarations: [AppComponent],
  providers: [
    MatSnackbarComponent,
    MessagingService,
    JwtAuthService,
    DeviceService,
    EventService,
    OrganiserService,
    MembershipService,
    AppConfig,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
