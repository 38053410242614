import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResultsService } from '../../../shared/services/api/events/results.service';
import { EventService } from 'app/shared/services/api/events/event.service';
import { HostEvent } from 'app/shared/models/api/host-event.model';
import { Result } from 'app/shared/models/api/result.model';

@Component({
  selector: 'app-results-model',
  templateUrl: './results.model.html',
  styleUrls: ['./results.model.scss']
})
export class ResultsModelComponent {
  displayedColumns = ['position', 'name', 'time', 'points'];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  results: any;
  hostEvent: HostEvent;
  filterTerm!: string;

  constructor(
    public dialogRef: MatDialogRef<ResultsModelComponent>,
    private resultsService: ResultsService,
    private eventService: EventService,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; resultData: any }
  ) {
    this.loadResults(data.resultData.subEventId);
  }

  loadResults(subEventId: number) {
    this.resultsService.get(subEventId).subscribe(results => {
      this.handleColumnRemoval(results[0]);
      this.results = results;
      this.loadHostEvent(this.data.resultData.eventId);
    });
  }

  loadHostEvent(eventId: number) {
    this.eventService.get(eventId).subscribe(hostEvent => {
      this.hostEvent = hostEvent;
    });
  }

  handleColumnRemoval(result: Result) {
    const columnsToCheck = ['position', 'time', 'points'] as const;
    let columnToKeep: keyof Result | undefined = undefined;

    for (const column of columnsToCheck) {
      if (result && result[column] !== undefined) {
        columnToKeep = column;
        break;
      }
    }

    if (columnToKeep !== undefined) {
      this.removeColumn(columnToKeep);
    }
  }

  removeColumn(column: keyof Result) {
    const columnsToRemove = ['position', 'time', 'points'].filter(col => col !== column);
    this.columnsToDisplay = this.displayedColumns.filter(col => !columnsToRemove.includes(col as keyof Result));
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
