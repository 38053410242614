import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-confirm',
  template: `<h1 matDialogTitle class="mb-8">{{ data.title }}</h1>
    <div mat-dialog-content class="mb-16">{{ data.message }}</div>
    <div mat-dialog-actions class="pb-16">
      <button type="button" mat-raised-button color="warn" (click)="dialogRef.close(true)">Delete</button>
      &nbsp;
      <span fxFlex></span>
      <button type="button" color="purple2" mat-raised-button (click)="dialogRef.close(false)">Cancel</button>
    </div>`,
})
export class AppComfirmComponent {
  constructor(public dialogRef: MatDialogRef<AppComfirmComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }
}
