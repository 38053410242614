import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class EntriesService {
  constructor(private http: HttpClient) { }

  enter(subEventId: number, data: unknown): Observable<any> {
    return this.http.post(`/user/event-entry/${subEventId}/enter`, data).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error:', error);
        return of(error);
      })
    );
  }

  enterById(subEventId: number, userId: number, data: unknown): Observable<any> {
    return this.http.post(`/user/event-entry/${subEventId}/enter?userId=${userId}`, data).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error:', error);
        return of(error);
      })
    );
  }

  editEntry(entryId: number, data: unknown) {
    return this.http.post(`/user/event-entry/${entryId}`, data).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error:', error);
        return of(error);
      })
    );
  }

  get(subEventId: number): Observable<any> {
    return this.http.get(`/sub-event/${subEventId}/list`).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error:', error);
        return of(error);
      })
    );
  }

  accept(userId: number): Observable<any> {
    return this.http.get(`/sub-event/${userId}/approve/`).pipe(
      map((data: Array<any>) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error:', error);
        return of(error);
      })
    );
  }

  deny(userId: number): Observable<any> {
    return this.http.get(`/sub-event/${userId}/deny/`).pipe(
      map((data: Array<any>) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error:', error);
        return of(error);
      })
    );
  }
}
