import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-filter-name-modal',
  templateUrl: './filter-name-modal.component.html',
  styleUrls: ['./filter-name-modal.component.css']
})
export class FilterNameModalComponent {
  filterName: string = '';

  constructor(public dialogRef: MatDialogRef<FilterNameModalComponent>) { }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    this.dialogRef.close(this.filterName);
  }
}
