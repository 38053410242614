<h1 mat-dialog-title>Editing {{data.athleteDetail.governingBodyType.governingBodyName}}</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill" class="half-width">
    <mat-label>Emergency Name</mat-label>
    <input matInput [(ngModel)]="data.athleteDetail.emergencyName" />
  </mat-form-field>
  <mat-form-field appearance="fill" class="half-width">
    <mat-label>Emergency Number</mat-label>
    <input matInput [(ngModel)]="data.athleteDetail.emergencyNumber" />
  </mat-form-field>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Club Name</mat-label>
    <input matInput [(ngModel)]="data.athleteDetail.clubName" />
  </mat-form-field>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Governing Body</mat-label>
    <mat-select [(ngModel)]="data.athleteDetail.governingBodyType.id" disabled>
      <mat-option disabled>
        <ngx-mat-select-search [(ngModel)]="membershipTypesFilterCtrl" placeholderLabel="Find Governing Body..."
          noEntriesFoundLabel="no matching Governing Body found"></ngx-mat-select-search>
      </mat-option>
      <ng-container *ngIf="filteredMembershipTypes$ | async as membershipTypes">
        <mat-option *ngIf="membershipTypes.length === 0" disabled>
          No Governing Body found
        </mat-option>
        <mat-option *ngFor="let membershipType of membershipTypes" [value]="membershipType.id">
          {{ membershipType.governingBodyName }}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Membership Number</mat-label>
    <input matInput [(ngModel)]="data.athleteDetail.membershipNumber" />
  </mat-form-field>
</div>
<div mat-dialog-actions class="align-end-container">
  <button mat-button color="purple2" (click)="onCancel()">Cancel</button>
  <!--<button mat-button color="red" (click)="deleteDetail()">Delete</button>-->
  <button mat-button color="green" (click)="saveDetail()" cdkFocusInitial>Ok</button>
</div>