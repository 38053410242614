<h1 mat-dialog-title>Enter Filter Name</h1>
<div mat-dialog-content>
  <mat-form-field class="full-width">
    <mat-label>Filter Name</mat-label>
    <input matInput [(ngModel)]="filterName" placeholder="Enter filter name" />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button (click)="onSave()" [disabled]="!filterName">Save</button>
</div>