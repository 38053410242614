import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { map, catchError } from "rxjs/operators";


@Injectable()
export class FiltersService {
  constructor(private http: HttpClient) { }

  list() {
    return this.http.get('/filters/list').pipe(
      map(data => {
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }

  add(form: any) {
    return this.http.post('/filters/save', form).pipe(
      map(data => {
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }

  edit(id: number, form: any) {
    return this.http.put(`/filters/edit/${id}`, form).pipe(
      map(data => {
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }

  remove(id: number) {
    return this.http.delete(`/filters/delete/${id}`).pipe(
      map(data => {
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }
}
