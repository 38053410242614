import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GoverningBody } from 'app/shared/models/api/governing-body.model';
import { User } from 'app/shared/models/api/user.model';
import { UserService } from 'app/shared/services/api/user.service';
import { startWith, map, takeUntil, BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'app-athlete-detail-model',
  templateUrl: './athlete-detail.model.html',
  styleUrls: ['./athlete-detail.model.scss']
})
export class AthleteDetailModelComponent {
  private _onDestroy = new Subject<void>();

  athleteDetail: any;
  membershipTypes: any;

  membershipTypesFilterCtrl: FormControl<string> = new FormControl<string>('');
  filteredMembershipTypes$ = new BehaviorSubject<GoverningBody[]>([]);

  constructor(
    public dialogRef: MatDialogRef<AthleteDetailModelComponent>,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: { athleteDetail: any, user: User }
  ) {

    this.membershipTypesFilterCtrl.valueChanges.pipe(
      startWith(''),
      map(value => this.filterMembershipTypes(value)),
      takeUntil(this._onDestroy)
    ).subscribe(filteredMembershipTypes => {
      this.filteredMembershipTypes$.next(filteredMembershipTypes);
    });

    this.userService
      .getMembershipTypes()
      .subscribe((membershipTypes: Array<GoverningBody>) => (this.membershipTypes = membershipTypes, this.filteredMembershipTypes$.next(this.membershipTypes)));
  }

  saveDetail() {
    this.userService.editRiderDetails(this.data.athleteDetail.id, {
      emergencyName: this.data.athleteDetail.emergencyName,
      emergencyNumber: this.data.athleteDetail.emergencyNumber,
      membershipNumber: this.data.athleteDetail.membershipNumber,
      user: this.data.user.id,
      governingBodyType: this.data.athleteDetail.governingBodyType.id
    }).subscribe((data) => {
      console.log(data);
      this.onCancel();
    })
  }

  deleteDetail() {
    console.log(this.data.athleteDetail.id)
  }

  private filterMembershipTypes(value: string): any[] {
    const allMembershipTypes = this.membershipTypes;
    if (!allMembershipTypes) {
      return [];
    }

    const filterValue = value.toLowerCase();

    return allMembershipTypes.filter(membership =>
      membership.governingBodyName.toLowerCase().includes(filterValue)
    );
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
