import { Component, ElementRef, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackbarComponent } from 'app/shared/components/mat-snackbar/mat-snackbar.component';
import { OrganiserService } from 'app/shared/services/api/organiser.service';
import SignaturePad from 'signature_pad';
import { EntriesService } from 'app/shared/services/api/events/entries.service';

@Component({
    selector: 'app-signature-model',
    templateUrl: './signature.component.html',
    styleUrls: ['./signature.component.scss']
})

export class SignatureComponent implements OnInit {
    signatureNeeded: boolean = true;
    signaturePad!: SignaturePad;
    @ViewChild('canvas') canvasEl!: ElementRef;
    signatureImg!: string;
    initialData: any;

    constructor(
        public dialogRef: MatDialogRef<SignatureComponent>,
        private snack: MatSnackbarComponent,
        private entriesService: EntriesService,
        @Inject(MAT_DIALOG_DATA) public data: { title: string; entry: any, showTos: boolean },
    ) {
        this.initialData = data.entry;
    }

    ngOnInit(): void { }

    ngAfterViewInit() {
        this.signaturePad = new SignaturePad(this.canvasEl.nativeElement, {
            backgroundColor: "rgb(255,255,255)",
        });
        this.signaturePad.addEventListener("endStroke", () => {
            this.signatureNeeded = this.signaturePad.isEmpty();
        }, { once: false });
    }

    savePad(value: any = undefined) {
        const base64Data = value || this.signaturePad.toDataURL();
        this.signatureNeeded = this.signaturePad.isEmpty();
        if (!this.signatureNeeded || value) {
            this.signatureNeeded = false;
            this.data.entry.athleteSignature = base64Data.replace("data:image/png;base64,", "");
            this.signaturePad.fromDataURL('data:image/png;base64,' + this.data.entry.athleteSignature);
        }
    }

    clearPad() {
        this.signatureNeeded = true;
        this.signaturePad.clear();
    }

    onCancel(): void {
        this.data.entry = this.initialData;
        this.dialogRef.close();
    }

    onFileSelected(): void {
        const inputNode: any = document.querySelector('#file');

        if (typeof (FileReader) !== 'undefined') {
            const reader = new FileReader();

            reader.onload = (e: any) => {
                this.savePad(e.target.result.replace("data:image/png;base64,", ""));
            };

            reader.readAsDataURL(inputNode.files[0]);
        }
    }

    enterEvent(): void {
        this.savePad();
        if (this.data.showTos) {
            console.log(this.data);
            this.entriesService
                .enter(this.data.entry.subEventId, {
                    athleteSignature: this.data.entry.athleteSignature?.replace('data:image/png;base64,', '')
                })
                .subscribe((response) => {
                    if (response.error) {
                        return;
                    }

                    this.snack.open(`${this.data.entry.firstName} ${this.data.entry.lastName} signed on.`);
                    this.onCancel();
                });
        } else {
            this.entriesService
                .enterById(this.data.entry.subEventId, this.data.entry.userId, {
                    athleteSignature: this.data.entry.athleteSignature?.replace('data:image/png;base64,', '')
                })
                .subscribe((response) => {
                    if (response.error) {
                        return;
                    }

                    this.snack.open(`${this.data.entry.firstName} ${this.data.entry.lastName} signed on.`);
                    this.onCancel();
                });
        }
    }
}