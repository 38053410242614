import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GoverningBody } from "app/shared/models/api/governing-body.model";
import { HostEvent } from "app/shared/models/api/host-event.model";
import { User } from "app/shared/models/api/user.model";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";


@Injectable()
export class UserService {
  constructor(private http: HttpClient) { }

  getProfile(): Observable<User> {
    return this.http.get(`/users/profile`).pipe(
      map((profile: User) => {
        return profile;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }

  getEntries(userId: number): Observable<any> {
    return this.http.get(`/user/event-entry/${userId}`).pipe(
      map((data: Array<any>) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }

  getUserById(id: number): Observable<User> {
    return this.http.get(`/user/${id}`).pipe(
      map((user: User) => {
        return user;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }

  getMembershipTypes(): Observable<Array<GoverningBody>> {
    return this.http.get('/membership-type/').pipe(
      map((data: Array<GoverningBody>) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }

  addRiderMembershipNumber(form) {
    // TODO: add type
    return this.http.post('/rider-membership-numbers/new', form).pipe(
      map((data) => {
        // TODO: add type
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }

  postRiderDetails(form: any) {
    // TODO: add type
    return this.http.post('/users/athlete-details', form).pipe(
      map((data) => {
        // TODO: add type
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }

  editRiderDetails(id: string, form: any) {
    // TODO: add type
    return this.http.post('/users/athlete-details/' + id, form).pipe(
      map((data) => {
        // TODO: add type
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }

  getEventsCreated(): Observable<Array<HostEvent>> {
    return this.http.get('/users/list-events').pipe(
      map((data: Array<HostEvent>) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error: ', error);
        return of(error);
      })
    );
  }

  getUserEnteredEvents(): Observable<Array<HostEvent>> {
    return this.http.get(`/user/event-entry/`).pipe(
      map((data: Array<Array<HostEvent>>) => {
        return data;
      }),
      catchError((error) => {
        console.log('found error:', error);
        return of(error);
      })
    );
  }

  enterSubEvent(data: { termsOfServiceAgreed: boolean; subEvent: any }): Observable<any> {
    return this.http.post(`/user/event-entry/enter`, data).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        console.log('found error:', error);
        return of(error);
      })
    );
  }

  submitProfileImage(profileImage: any): Observable<any> {
    return this.http.post(`/user/upload-profile-picture`, profileImage).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        console.log('found error:', error);
        return of(error);
      })
    );
  }
}
