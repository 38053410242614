<mat-toolbar class="topbar mat-bg-card">
  <!-- Sidenav toggle button -->
  <button *ngIf="layoutConf.sidebarStyle !== 'compact' && layoutConf.sidebarStyle === 'closed'" mat-icon-button
    id="sidenavToggle" (click)="toggleSidenav()" matTooltip="Show Navigation">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>

  <span fxFlex></span>
  <!-- Language Switcher -->
  <!-- TODO: Translations
    <button mat-button [matMenuTriggerFor]="menu">
    <span class="flag-icon {{currentLang.flag}} mr-8"></span>
    <span>{{currentLang.name}}</span>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngFor="let lang of availableLangs" (click)="setLang(lang)">
      <span class="flag-icon mr-8 {{lang.flag}}"></span>
      <span>{{lang.name}}</span>
    </button>
  </mat-menu>-->

  <!--<app-egret-search-input-over placeholder="Country (e.g. US)" resultPage="/search"> </app-egret-search-input-over> -->
  <!-- Open "views/search-view/result-page.component" to understand how to subscribe to input field value -->

  <!-- Notification toggle button -->
  <!--<button mat-icon-button matTooltip="Notifications" (click)="toggleNotific()" [style.overflow]="'visible'"
    class="topbar-button-right">
    <mat-icon>notifications</mat-icon>
    <span class="notification-number mat-bg-warn">3</span>
  </button>-->
  <!-- Top left user menu -->
  <button mat-icon-button [matMenuTriggerFor]="accountMenu" class="topbar-button-right img-button">
    <mat-icon>menu</mat-icon>
  </button>

  <mat-menu #accountMenu="matMenu">
    <button mat-menu-item [routerLink]="['/profile']">
      <mat-icon>account_box</mat-icon>
      <span>Profile</span>
    </button>
    <button mat-menu-item [routerLink]="['/register/athlete']">
      <mat-icon>settings</mat-icon>
      <span>
        <ng-container *ngIf="!authGuard.hasAccess(riderRole); then upgradeAccount; else riderInfo">
        </ng-container>
        <ng-template #upgradeAccount>Upgrade Account To Athlete</ng-template>
        <ng-template #riderInfo>Rider Information Settings</ng-template>
      </span>
    </button>
    <!-- TODO: disabled for now
    <button mat-menu-item [routerLink]="['/billing/']">
      <mat-icon>manage_accounts</mat-icon>
      <span>Billing
      </span>
    </button> -->
    <!-- TODO: disabled for now
      <button mat-menu-item>
      <mat-icon>notifications_off</mat-icon>
      <span>Disable alerts</span>
    </button> -->
    <button mat-menu-item [routerLink]="['/register/setup-organiser']" *ngIf="!authGuard.hasAccess(organiserRole)">
      <mat-icon>star</mat-icon>
      <span>Become an Organiser</span>
    </button>
    <button mat-menu-item (click)="jwtAuth.signOut()">
      <mat-icon>exit_to_app</mat-icon>
      <span>{{ "SIGNOUT" | translate }}</span>
    </button>
  </mat-menu>
</mat-toolbar>