<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>Signature</mat-label>
    <canvas #canvas width="400" height="200"></canvas>
    <p class="error-message" *ngIf="signatureNeeded">
      Signature is required for Sign On
    </p>

    <div class="flex justify-between">
      <button mat-raised-button (click)="clearPad()">Clear</button>
      <button mat-raised-button (click)="fileInput.click()" *ngIf="signatureNeeded">
        Upload Signature
      </button>
      <input hidden (change)="onFileSelected()" #fileInput type="file" id="file" />
    </div>
    <input matInput [(ngModel)]="data.entry.athleteSignature" [hidden]="true" />
  </mat-form-field>
</div>
<h3 *ngIf="data.showTos">
  By signing, you agree to the British Cycling
  <a href="https://www.britishcycling.org.uk/about/article/Website-Terms-and-Conditions-0" target="_blank">Terms and
    Conditions</a>.
</h3>
<div mat-dialog-actions class="align-end-container">
  <button mat-button color="purple2" (click)="onCancel()">Cancel</button>
  <button mat-button color="orange" (click)="enterEvent()" *ngIf="!data.entry.timeOfSignOn && !signatureNeeded">
    Sign On
  </button>
</div>