import { Component, OnInit, Input, ViewChildren, Renderer2, QueryList } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { LayoutService } from '../../services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { JwtAuthService } from '../../services/api/auth/jwt-auth.service';
import { EgretNotifications2Component } from '../egret-notifications2/egret-notifications2.component';
import { config } from 'config';
import { UserRoleGuard } from 'app/shared/guards/user-role.guard';

@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html',
})
export class HeaderSideComponent implements OnInit {
  @Input() notificPanel: any;
  @ViewChildren(EgretNotifications2Component) noti: QueryList<any>;
  organiserRole: Array<string> = config.authRoles.organiser;
  riderRole: Array<string> = config.authRoles.rider;
  spectatorRole: Array<string> = config.authRoles.spectator;
  public availableLangs = [
    {
      name: 'EN',
      code: 'en',
      flag: 'flag-icon-gb',
    },
    {
      name: 'ES',
      code: 'es',
      flag: 'flag-icon-es',
    },
  ];
  currentLang = this.availableLangs[0];

  public egretThemes: any[];
  public layoutConf: any;

  constructor(
    private themeService: ThemeService,
    private layout: LayoutService,
    public translate: TranslateService,
    private renderer: Renderer2,
    public authGuard: UserRoleGuard,
    public jwtAuth: JwtAuthService
  ) { }

  ngOnInit() {
    this.egretThemes = this.themeService.egretThemes;
    this.layoutConf = this.layout.layoutConf;
    this.translate.use(this.currentLang.code);
  }

  setLang(lng) {
    this.currentLang = lng;
    this.translate.use(lng.code);
  }

  changeTheme(theme) {
    // this.themeService.changeTheme(theme);
  }

  toggleNotific() {
    this.notificPanel.toggle();
  }

  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full',
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed',
    });
  }

  toggleCollapse() {
    if (this.layoutConf.sidebarStyle === 'compact') {
      return this.layout.publishLayoutChange(
        {
          sidebarStyle: 'full',
          sidebarCompactToggle: false,
        },
        { transitionClass: true }
      );
    }

    this.layout.publishLayoutChange(
      {
        sidebarStyle: 'compact',
        sidebarCompactToggle: true,
      },
      { transitionClass: true }
    );
  }

  onSearch(e) { }
}
